import { Injectable, signal } from '@angular/core';

interface Notification {
  message: string;
  icon: string;
  color: string;
}

@Injectable({
  providedIn: 'root'
})
export class UiService {
  private _loading = signal(true);
  private _notification = signal<Notification | null>(null);

  stopLoading() {
    this._loading.set(false);
  }

  getLoading() {
    return this._loading.asReadonly();
  }

  // --------------------------------

  setNotification(notification: Notification) {
    this._notification.set(notification);
  }

  getNotification() {
    return this._notification.asReadonly();
  }

  hideNotification() {
    this._notification.set(null);
  }
}
